import { PrecioDescuentoCabVM, PrecioDescuentoDetVM } from "core/domain/inventario/PrecioDescuentoVM"


export interface PrecioVentaState {
    listado: PrecioDescuentoCabVM[],
    precioVenta: PrecioDescuentoCabVM,
    item: PrecioDescuentoDetVM
}

export const initialState: PrecioVentaState = {
    listado: [],
    precioVenta: {} as PrecioDescuentoCabVM,
    item: {
        idCab: 0,
        idDet: 0,
        idProducto: 0,
        producto: '',
        valor: 0
    }
}