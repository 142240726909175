import * as page from '../pages'

export const rutasPrivadas = [
    { path: '/', component: <page.Bienvenida /> },
    { path: '/pruebas', component: <page.Pruebas /> },

    // GENERAL
    { path: '/general/mantenimiento/sucursal', component: <page.Sucursal /> },
    { path: '/general/mantenimiento/formulario-sucursal/:accion/:id', component: <page.FormularioSucursal /> },

    // INVENTARIO
    { path: '/inventario/mantenimiento/categoria', component: <page.Categoria /> },
    { path: '/inventario/mantenimiento/producto', component: <page.Producto /> },
    { path: '/inventario/mantenimiento/formulario-producto/:accion/:id/:externo', component: <page.FormularioProducto /> },
    { path: '/inventario/mantenimiento/precios-venta', component: <page.PrecioVenta /> },
    { path: '/inventario/mantenimiento/formulario-precio-venta/:accion/:id', component: <page.FormularioPrecioVenta /> },

    // MEDICINA
    { path: '/medicina/mantenimiento/doctor', component: <page.Doctor /> },
    { path: '/medicina/mantenimiento/formulario-doctor/:accion/:id', component: <page.FormularioDoctor /> },
    { path: '/medicina/transaccion/agendar-cita', component: <page.AgendarCita /> },
    { path: '/medicina/transaccion/gestion-citas', component: <page.GestionCitas /> },
    { path: '/medicina/transaccion/recepcion', component: <page.Recepcion /> },
    { path: '/medicina/transaccion/enfermeria', component: <page.Enfermeria /> },
    { path: '/medicina/transaccion/consulta', component: <page.Consulta /> },
    { path: '/medicina/consulta/historial-clinico', component: <page.HistorialClinico /> },

    // NOMINA
    { path: '/nomina/mantenimiento/cargo', component: <page.Cargo /> },
    { path: '/nomina/mantenimiento/formulario-cargo/:accion/:id', component: <page.FormularioCargo /> },
    { path: '/nomina/mantenimiento/departamento', component: <page.Departamento /> },
    { path: '/nomina/mantenimiento/formulario-departamento/:accion/:id', component: <page.FormularioDepartamento /> },
    { path: '/nomina/mantenimiento/empleado', component: <page.Empleado /> },
    { path: '/nomina/mantenimiento/formulario-empleado/:accion/:id/:externo', component: <page.FormularioEmpleado /> },

    // SEGURIDAD
    { path: '/seguridad/pruebas', component: <page.Pruebas /> },
    { path: '/seguridad/mantenimiento/rol', component: <page.Rol /> },
    { path: '/seguridad/mantenimiento/formulario-rol/:accion/:id', component: <page.FormularioRol /> },
    { path: '/seguridad/mantenimiento/usuario', component: <page.Usuario /> },
    { path: '/seguridad/mantenimiento/formulario-usuario/:accion/:id', component: <page.FormularioUsuario /> },
    { path: '/seguridad/transaccion/accesos', component: <page.Accesos /> },

    // VENTA
    { path: '/venta/mantenimiento/empresa', component: <page.Empresa /> },
    { path: '/venta/mantenimiento/formulario-empresa/:accion/:id', component: <page.FormularioEmpresa /> },
    { path: '/venta/mantenimiento/cliente', component: <page.Cliente /> },
    { path: '/venta/mantenimiento/formulario-cliente/:accion/:id/:externo', component: <page.FormularioCliente /> },
    { path: '/venta/transaccion/pre-facturas', component: <page.PreFacturas /> },
    { path: '/venta/transaccion/factura-venta', component: <page.FacturaVenta /> },

];