import React from "react";
import { Route, Routes } from "react-router-dom";
import Cabecera from "../layout/Cabecera";
import ContenidoPrincipal from "../layout/ContenidoPrincipal";
import { rutasPrivadas } from '../../routes/rutasPrivadas';
import * as page from '../../pages/index'


function PlantillaConAutenticacion() {

    const routeComponents = rutasPrivadas.map(({ path, component }, key) => <Route exact path={path} element={component} key={key} />);

    return (
        // <div className="skin-megna fixed-layout">
        <div id="main-wrapper">
            <Cabecera />
            <ContenidoPrincipal>
                <Routes basename={'/'}>
                    {/* <Route path="/" element={<page.NotFound/>}>
                        {routeComponents}
                        <Route path='*' component={page.NotFound} />
                    </Route> */}
                    {/* <Route path="/general/mantenimiento/sucursal" element={<page.Sucursal/>}/>                    
                    <Route path="/general/mantenimiento/formulario-sucursal/:accion/:id" element={<page.FormularioSucursal/>}/>                    
                    <Route path="/medicina/mantenimiento/doctor" element={<page.Doctor/>}/> */}
                    {routeComponents}
                </Routes>
            </ContenidoPrincipal>
        </div>
        // </div>
    )
}

export default PlantillaConAutenticacion;