import { MaestroVM } from "core/domain/comun/MaestroVM";
import { ProductoVM } from "core/domain/inventario/ProductoVM";

export interface ProductoState {
    listadoProducto: ProductoVM[],
    filtros: {        
        idCategoria: number,
        idEstado: string | null,
        nombre: string | null
    },    
    producto: ProductoVM

}

export const initialState: ProductoState = {
    listadoProducto: [],
    filtros: {        
        idCategoria: 0,
        idEstado: null,
        nombre: null
    },    
    producto: {} as ProductoVM
}