import Loader from "components/common/loader";
import { loadMessages, locale } from "devextreme/localization";
import esMessages from "devextreme/localization/messages/es.json";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "redux/store";
import PlantillaConAutenticacion from "./components/layout/PlantillaConAutenticacion";
import PlantillaSinAutenticacion from "./components/layout/PlantillaSinAutenticacion";
import { AppProvider, useApp } from './contexts/seguridad/AuthProvider';

function App() {
  const { stateApp } = useApp();

  const token = sessionStorage.getItem('token');

  React.useEffect(() => {
    loadMessages(esMessages);
    locale(navigator.language);
  })



  if (token !== null || stateApp.token !== null)
    return <PlantillaConAutenticacion />;
  else
    return <PlantillaSinAutenticacion />;



}

const Inicio = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <AppProvider>
            <App />
          </AppProvider>
        </Provider>
      </Suspense>
    </Router>
  );
}

export default Inicio
