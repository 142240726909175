import { ClienteVM } from "core/domain/venta/ClienteVM";

export interface ClienteState {
    listadoCliente: ClienteVM[],
    cliente: ClienteVM,
    clienteValido: "valid" | "invalid" | "pending"
}

export const initialCliente: ClienteVM = {
    idPersona: 0,
    nombre: "",
    tipoPersona: "",
    tipoDocumento: "",
    numeroDocumento: "",
    telefonoYCelular: "",
    telefono: "",
    celular: "",
    idPais: 0,
    idProvincia: 0,
    idCiudad: 0,
    direccion: "",
    email: "",
    sexo: "",
    idEstadoCivil: 0,
    fechaNacimiento: null,
    foto: "",
    idUsuario: 0,
    idTipoCliente: 0,
    tipoCliente: "",
    idGrupoPrecioVenta: 0,
    idEstado: "",
    estado: "",
    catalogos: []
}

export const initialState: ClienteState = {
    listadoCliente: [],
    cliente: initialCliente,
    clienteValido: "valid"
}


