import { Link, useNavigate } from "react-router-dom";
import { useApp } from '../../../contexts/seguridad/AuthProvider';
import { Offcanvas, Nav, Row, Col } from "react-bootstrap"
import { useState } from "react";
import './style.scss'
import icono from '../../../assets/images/logo.png'

function Menu({ show, handleClose }) {

    const { stateApp } = useApp();
    const navigate = useNavigate();
    const [state, setState] = useState({
        moduloActivo: '',
        menuActivo: '',
        texoBusqueda: ''
    });
    const onSetModulo = (m) => setState({ ...state, moduloActivo: state.moduloActivo === m ? '' : m });
    const onSetMenu = (mod, mnu) => {
        setState({ ...state, moduloActivo: mod, menuActivo: mnu, texoBusqueda: '' })
        handleClose()
        navigate(mnu);
    };

    const onChange = (e) => {
        setState({ ...state, texoBusqueda: e.target.value })
    }

    function BusquedaMenu() {
        return (
            <Nav>
                {stateApp.menu?.modulos && stateApp.menu.modulos.map((mod) => {
                    return (
                        mod.subModulos.map((sub) => {
                            return (
                                sub.opciones.filter(x => x.opcion.toLowerCase().includes(state.texoBusqueda.toLowerCase())).map((op) => {
                                    return (
                                        <Nav.Item>
                                            <div role={'button'} to={op.url} onClick={() => onSetMenu(mod.modulo, op.url)} >
                                                <div className={op.url == state.menuActivo ? 'menuItem menuActivo' : 'menuItem'}>
                                                    <i className={"far fa-circle sm"}></i>
                                                    <span className="titulo-menu">{mod.modulo} / {op.opcion}</span>
                                                </div>
                                            </div>
                                        </Nav.Item>
                                    )
                                })
                            )
                        })
                    )
                })}
            </Nav>)
    }

    function Menu() {
        return (
            <Nav>
                {stateApp.menu?.modulos && stateApp.menu.modulos.map((mod) => {
                    return (
                        <Nav.Item>
                            <Link to="#" onClick={() => onSetModulo(mod.modulo)}>
                                <div className={mod.modulo == state.moduloActivo ? 'menuItem moduloActivo' : 'menuItem'}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div>
                                            <i className={mod.icono}></i>
                                            <span className="titulo-menu">{mod.modulo}</span>
                                        </div>
                                        <i class={mod.modulo == state.moduloActivo ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}></i>
                                    </div>
                                </div>
                            </Link>
                            <ul className={mod.modulo === state.moduloActivo ? "lista abrir" : "lista cerrar"}>
                                {mod.subModulos.map((sub) => {
                                    return (
                                        sub.opciones.map((op) => {
                                            return (
                                                <li key={op.url}>
                                                    <div role={'button'} to={op.url} onClick={() => onSetMenu(mod.modulo, op.url)} >
                                                        <div className={op.url == state.menuActivo ? 'menuItem menuActivo' : 'menuItem'}>
                                                            <i className={"far fa-circle sm"}></i>
                                                            <span className="titulo-menu">{op.opcion}</span>
                                                        </div>
                                                    </div>
                                                </li>)
                                        })
                                    )
                                })}
                            </ul>
                        </Nav.Item>)
                })}
            </Nav>
        );
    }

    return (
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header >
                <div>
                    <div role={'button'}  onClick={handleClose} title="Cerrar"
                        className="d-flex flex-row justify-content-end align-items-end">
                       <i class="fas fa-times cerrar"></i>
                    </div>
                    <Row className="header">
                        <Col className="text-center">
                            <img src={icono} className="img-fluid mb-2" />
                            <input type={"text"} className="busqueda" placeholder="Buscar menú..." onChange={onChange} />
                        </Col>
                    </Row>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {state.texoBusqueda.trim().length > 0 ? <BusquedaMenu /> : <Menu />}
            </Offcanvas.Body>
        </Offcanvas>

    )

}

export default Menu;