import gif from './loader.gif';

const Loader = () => {
    
    return (
        <>

            <div 
                className='fixed-top'
                style={{
                    background: 'url('+gif+') no-repeat center center fixed',
                    height: "100vh",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover", 
                    backgroundColor: "white"
                }}>
            </div>
        </>

    );
}
export default Loader;