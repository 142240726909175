import { ClienteVM } from "core/domain/venta/ClienteVM"
import { ExistePersonaVM } from "core/domain/comun/ExistePersonaVM"
import { initialCliente } from "../mantenimiento/ClienteState"
import { FacturaVentaVM, initialFacturaVenta } from "core/domain/venta/FacturaVentaVM"


export interface FacturaVentaState {
    cliente: ExistePersonaVM,
    consumidorFinal: ClienteVM,
    productos: any,
    productoABuscar: string,
    idProducto: number | null,    
    buscandoProducto: boolean,
    factura: FacturaVentaVM,
    modalEditarProducto:boolean,
    modalCliente:boolean,
    accionCliente:string
}

export const initialState: FacturaVentaState = {
    cliente: { existe: false, persona: initialCliente },
    consumidorFinal: { ...initialCliente, idPersona: -1, nombre: 'Consumidor final', numeroDocumento: '9999999999999' },
    productos: [],
    productoABuscar: '',
    idProducto: null,    
    buscandoProducto: false,
    factura: initialFacturaVenta,
    modalEditarProducto:false,
    modalCliente:false,
    accionCliente:''
}


