import { EmpleadoVM } from "core/domain/nomina/EmpleadoVM";

export interface EmpleadoState {
    listadoEmpleado: EmpleadoVM[],
    empleado: EmpleadoVM,
    empleadoValido: "valid" | "invalid" | "pending"
}

const initialEmpleado: EmpleadoVM = {
    idPersona: 0,
    nombre: "",
    tipoPersona: "",
    tipoDocumento: "",
    numeroDocumento: "",
    telefonoYCelular: "",
    telefono: "",
    celular: "",
    idPais: 0,
    idProvincia: 0,
    idCiudad: 0,
    direccion: "",
    email: "",
    sexo: "",
    idEstadoCivil: 0,
    fechaNacimiento: null,
    foto: "",
    idUsuario: 0,
    idEstado: "",
    estado: "",
    catalogos: [],
    idDepartamento: 0,
    idCargo: 0,
    idTipoContrato: 0,
    idTipoPago: 0,
    discapacidad: false,
    porcentajeDiscapacidad: 0
}

export const initialState: EmpleadoState = {
    listadoEmpleado: [],
    empleado: initialEmpleado,
    empleadoValido: "valid"
}


