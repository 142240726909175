import {ProductoVM} from '../inventario/ProductoVM'

export interface FacturaVentaVM  { 
    idVenta:string,   
    idCliente:number,
    productos:ItemProductoVM[],
    subtotalConIva:number,
    subtotalSinIva:number,
    descuento:number,
    iva:number,
    total:number
}

export interface ItemProductoVM{
    idProducto:number,
    producto:string,
    cantidad:number,
    precioVenta:number,
    descuento:number,
    iva:number,
    total:number
}

export const initialFacturaVenta: FacturaVentaVM={
    idVenta:'',
    idCliente: 0,
    productos: [] ,
    subtotalConIva: 0,
    subtotalSinIva: 0,
    descuento: 0,
    iva: 0,
    total: 0
}
