import { CategoriaVM } from "core/domain/inventario/CategoriaVM";

export interface CategoriaState {
    listadoCategoria: CategoriaVM[],
    categoriaSeleccionada: CategoriaVM,
    categoriaAGrabar: CategoriaVM,
    refreshCategoria: number,
    modal:boolean,
    accion:'I'|'U'|'D'
}

export const initialState: CategoriaState = {
    listadoCategoria: [],
    categoriaSeleccionada: {} as CategoriaVM,
    categoriaAGrabar: {} as CategoriaVM,
    refreshCategoria: Math.random(),
    modal:false,
    accion:'I',
}