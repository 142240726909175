import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'redux/state/venta/mantenimiento/ClienteState'
import { ClienteVM } from 'core/domain/venta/ClienteVM'
import { PersonaVM } from 'core/domain/comun/ExistePersonaVM'

export const clienteSlice = createSlice({
    name: 'cliente',
    initialState,
    reducers: {
        setField: (state, action: PayloadAction<{ campo: string, valor:  any }>) => {
            return { ...state, [action.payload.campo]: action.payload.valor }
        },  
        setFieldCliente: (state, action: PayloadAction<{ campo: string, valor:  ClienteVM|ClienteVM[] }>) => {
            return { ...state, cliente:{...state.cliente, [action.payload.campo]: action.payload.valor} }
        },       
    },
})

export const { setField,setFieldCliente } = clienteSlice.actions

export default clienteSlice.reducer