import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'redux/state/inventario/mantenimiento/PrecioVentaState'
import { PrecioDescuentoCabVM, PrecioDescuentoDetVM } from 'core/domain/inventario/PrecioDescuentoVM'

export const precioVentaSlice = createSlice({
    name: 'precioVenta',
    initialState,
    reducers: {
        setField: (state, action: PayloadAction<{ campo: string, valor: PrecioDescuentoCabVM|PrecioDescuentoCabVM[]|PrecioDescuentoDetVM }>) => {
            return { ...state, [action.payload.campo]: action.payload.valor }
        },
        setFieldPrecioVenta: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state,precioVenta:{...state.precioVenta,[action.payload.campo]: action.payload.valor} }
        },  
        setFieldItemPrecioVenta: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state,item:{...state.item,[action.payload.campo]: action.payload.valor} }
        },       
    },
})

export const { setField,setFieldPrecioVenta,setFieldItemPrecioVenta } = precioVentaSlice.actions

export default precioVentaSlice.reducer