import icono from '../../assets/icons/icono.png'
function Logo() {
    return (
        <div className="pl-3 text-center">           
            <img src={icono} className='img-fluid'/>
            <span className="d-none d-lg-block text-white"><span className="text-white font-bold">SIS</span>MEDIC</span>
        </div>
    )
}

export default Logo;