import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'redux/state/medicina/transaccion/CitaMedicaState'
import { CitaPorEstadoVM } from 'core/domain/medicina/CitaPorEstadoVM'
import { CitaMedicaVM } from 'core/domain/medicina/CitaMedicaVM'
import { OrdenExamenCabVM } from 'core/domain/medicina/OrdenExamenVM'

export const citaMedicaSlice = createSlice({
    name: 'citaMedica',
    initialState,
    reducers: {
        setListadoCitas: (state, action: PayloadAction<CitaPorEstadoVM>) => {
            return {
                ...state,
                citas: action.payload.citas,
                catalogos: {
                    sexo: action.payload.sexo?.length > 0 ? action.payload.sexo : state.catalogos.sexo,
                    cie10: action.payload.cie10?.length > 0 ? action.payload.cie10 : state.catalogos.cie10,
                    vademecum: action.payload.vademecum?.length > 0 ? action.payload.vademecum : state.catalogos.vademecum,
                    examenesLaboratorio: action.payload.examenesLaboratorio?.length > 0 ? action.payload.examenesLaboratorio : state.catalogos.examenesLaboratorio,
                    examenesImagen: action.payload.examenesImagen?.length > 0 ? action.payload.examenesImagen : state.catalogos.examenesImagen
                }
            }
        },
        setCita: (state, action: PayloadAction<CitaMedicaVM>) => {
            return {
                ...state,
                cita: action.payload,
            }
        },

        setFieldUtil: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state, util: { ...state.util, [action.payload.campo]: action.payload.valor } }
        },
        setFieldPaciente: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return {
                ...state,
                cita: {
                    ...state.cita,
                    paciente: {
                        ...state.cita.paciente,
                        [action.payload.campo]: action.payload.valor
                    }
                }
            }
        },
        setFieldAntecedenteFamiliar: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return {
                ...state,
                cita: {
                    ...state.cita,
                    paciente: {
                        ...state.cita.paciente,
                        historialAntecedenteFamiliar: {
                            ...state.cita.paciente.historialAntecedenteFamiliar,
                            [action.payload.campo]: action.payload.valor
                        },
                    }
                }
            }
        },
        setFieldAntecedentePersonal: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return {
                ...state,
                cita: {
                    ...state.cita,
                    paciente: {
                        ...state.cita.paciente,
                        historialAntecedentePersonal: {
                            ...state.cita.paciente.historialAntecedentePersonal,
                            [action.payload.campo]: action.payload.valor
                        },
                    }
                }
            }
        },
        setFieldHabito: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return {
                ...state,
                cita: {
                    ...state.cita,
                    paciente: {
                        ...state.cita.paciente,
                        habitos: {
                            ...state.cita.paciente.habitos,
                            [action.payload.campo]: action.payload.valor
                        },
                    }
                }
            }
        },

        setFieldAlergia: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return {
                ...state,
                cita: {
                    ...state.cita,
                    paciente: {
                        ...state.cita.paciente,
                        alergias: {
                            ...state.cita.paciente.alergias,
                            [action.payload.campo]: action.payload.valor
                        },
                    }
                }
            }
        },
        setFieldSignoVital: (state, action: PayloadAction<{ campo: string, valor: any }>) => {

            const estado = {
                ...state,
                cita: {
                    ...state.cita,
                    [action.payload.campo]: action.payload.valor
                }
            }

            const peso = Number(estado.cita.peso ?? 0);
            const talla = Number(estado.cita.talla ?? 0);

            return {
                ...estado,
                cita: {
                    ...estado.cita,
                    imc: talla == 0 ? 0 : Number((peso / Math.pow(talla, 2)).toFixed(2))
                }
            }
        },
        setFieldCita: (state, action: PayloadAction<{ campo: string, valor: any }>) => {            

            return {
                ...state,
                cita: {
                    ...state.cita,
                    [action.payload.campo]: action.payload.valor
                }
            }
        },
        setFieldPedidoLaboratorio: (state, action: PayloadAction<{ campo: string, valor: any }>) => {            

            return {
                ...state,
                cita: {
                    ...state.cita,
                    examenesLaboratorio:{
                        ...state.cita.examenesLaboratorio,
                        [action.payload.campo]:action.payload.valor
                    }                    
                }
            }
        },
        setFieldPedidoImagen: (state, action: PayloadAction<{ campo: string, valor: any }>) => {            

            return {
                ...state,
                cita: {
                    ...state.cita,
                    examenesLaboratorio:{
                        ...state.cita.examenesLaboratorio,
                        [action.payload.campo]:action.payload.valor
                    }                    
                }
            }
        },
    },
})

export const {
    setListadoCitas,
    setCita,
    setFieldUtil,
    setFieldPaciente,
    setFieldAntecedenteFamiliar,
    setFieldAntecedentePersonal,
    setFieldHabito,
    setFieldAlergia,
    setFieldSignoVital,
    setFieldCita,
    setFieldPedidoLaboratorio,
    setFieldPedidoImagen
} = citaMedicaSlice.actions

export default citaMedicaSlice.reducer