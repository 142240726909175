import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'redux/state/venta/transaccion/FacturaVentaState'
import { ExistePersonaVM } from 'core/domain/comun/ExistePersonaVM'
import { initialCliente } from 'redux/state/venta/mantenimiento/ClienteState'
import { ItemProductoVM } from 'core/domain/venta/FacturaVentaVM'

const recalcularTotales=(productos:ItemProductoVM[]):any =>{
    let subtotalConIva = 0
    let subtotalSinIva = 0
    let descuento = 0
    let iva = 0
    let total = 0

    productos?.map((item) => {
        subtotalSinIva += item.total
    })

    total = subtotalConIva + subtotalSinIva - descuento + iva

    return {               
        subtotalConIva,
        subtotalSinIva,
        iva,
        total                  
    }
}

export const facturaVentaSlice = createSlice({
    name: 'facturaVentaSlice',
    initialState,
    reducers: {
        setField: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state, [action.payload.campo]: action.payload.valor }
        },
        setFieldCliente: (state, action: PayloadAction<ExistePersonaVM>) => {
            return { ...state, cliente: action.payload }
        },
        setFieldIdentificacionCliente: (state, action: PayloadAction<{ identificacion: string }>) => {
            return {
                ...state, cliente: {
                    ...state.cliente,
                    persona: {
                        ...state.cliente.persona,
                        numeroDocumento: action.payload.identificacion
                    }
                }
            }
        },
        setCleanCliente: (state) => {
            return { ...state, cliente: { existe: false, persona: initialCliente }, factura: { ...state.factura, idCliente: 0 } }
        },        
        setItemProductoFactura: (state, action: PayloadAction<ItemProductoVM>) => {
            const productos = [
                ...state.factura.productos,
                action.payload
            ]

            const {subtotalConIva,subtotalSinIva,iva,total}=recalcularTotales(productos)

            return {
                ...state,
                factura: {
                    ...state.factura,
                    productos: [
                        ...state.factura.productos,
                        action.payload
                    ],
                    subtotalConIva,
                    subtotalSinIva,
                    iva,
                    total
                }
            }
        },
        removeItemProductoFactura: (state, action: PayloadAction<{ idProducto: number }>) => {            
            const items = state.factura.productos.filter(x => x.idProducto !== action.payload.idProducto)
         
            const {subtotalConIva,subtotalSinIva,iva,total}=recalcularTotales(items)

            return {
                ...state,
                factura: {
                    ...state.factura,
                    productos: items,
                    subtotalConIva,
                    subtotalSinIva,
                    iva,
                    total                  
                }
            }
        },
        setUpdateProductoFactura: (state, action: PayloadAction<ItemProductoVM>) => {

            const productos = state.factura.productos.map((item) => {
                if (item.idProducto === action.payload.idProducto) {
                    return action.payload
                }
                return item
            })

            const {subtotalConIva,subtotalSinIva,iva,total}=recalcularTotales(productos)

            return {
                ...state,
                factura: {
                    ...state.factura,
                    productos:productos,
                    subtotalConIva,
                    subtotalSinIva,
                    iva,
                    total       
                }
            }
        },
    },
})

export const {
    setField,
    setFieldCliente,
    setFieldIdentificacionCliente,
    setCleanCliente,
    setItemProductoFactura,
    removeItemProductoFactura,
    setUpdateProductoFactura } = facturaVentaSlice.actions

export default facturaVentaSlice.reducer