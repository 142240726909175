import { Link, useNavigate } from "react-router-dom";
import { useApp } from '../../contexts/seguridad/AuthProvider';

function PerfilSuperior() {
    const navigate = useNavigate();
    const { stateApp, setStateApp } = useApp();

    async function Logout() {
        console.log('LOGOUT');
        sessionStorage.clear();
        setStateApp({
            user: null,
            menu: [],
            token: null
        });

        // <Redirect to="/" />
        navigate("/");
        //window.location.reload();
    }

    return (
        <li className="nav-item dropdown u-pro">
            <Link className="nav-link dropdown-toggle waves-effect waves-dark profile-pic"
                to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {/* <img src="imagenes/1.jpg" alt="user" className="" /> */}
                <span className="hidden-md-down">{stateApp.user} &nbsp;<i className="fa fa-angle-down"></i></span>
            </Link>
            <div className="dropdown-menu dropdown-menu-right animated flipInY">
                {/*<Link to="#" className="dropdown-item"><i className="ti-user"></i> Mi perfil</Link>
                <Link to="#" className="dropdown-item"><i className="ti-wallet"></i> My Balance</Link>
                <Link to="#" className="dropdown-item"><i className="ti-email"></i> Inbox</Link>
                <div className="dropdown-divider"></div>
                <Link to="#" className="dropdown-item"><i className="ti-settings"></i> Account Setting</Link> */}
                <div className="dropdown-divider"></div>
                <Link to="#" onClick={Logout} className="dropdown-item"><i className="fa fa-power-off"></i> Cerrar sesión</Link>
            </div>
        </li>
    )
}

export default PerfilSuperior;