import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { inventarioReducer } from './slice/inventario'
import { ventaReducer } from './slice/venta'
import { nominaReducer } from './slice/nomina'
import { medicinaReducer } from './slice/medicina'

const rootReducer = combineReducers({
  inventario: inventarioReducer,
  venta: ventaReducer,
  nomina: nominaReducer,
  medicina:medicinaReducer
})

export const store = configureStore({
  reducer: rootReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector