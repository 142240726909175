import { Link } from "react-router-dom";
import { useState } from "react";
import { useApp } from "contexts/seguridad/AuthProvider";
import Menu from "./menu";


function Busqueda() {
    const { stateApp } = useApp();
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <ul className="navbar-nav mr-auto">
                {/* <li className="nav-item"> <Link className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" to="/"><i className="ti-menu"></i></Link> </li> */}

                <li className="nav-item" onClick={handleShow}>
                    <Link className="nav-link sidebartoggler waves-effect waves-dark" to="#">
                        <i className="fas fa-bars"></i>
                    </Link>                    
                </li>

            </ul>

            <Menu show={show} handleClose={handleClose}/>   
        </>
    )
}

export default Busqueda;