import { CitaMedicaVM } from 'core/domain/medicina/CitaMedicaVM'
import { ListadoCitaVM } from 'core/domain/medicina/ListadoCitaVM'
import { MaestroVM } from '../../../../core/domain/comun/MaestroVM'

export interface CitaMedicaState {
    citas: ListadoCitaVM[],
    cita: CitaMedicaVM,
    catalogos: {
        sexo: MaestroVM[],
        cie10: MaestroVM[],
        vademecum: MaestroVM[],
        examenesLaboratorio: MaestroVM[],
        examenesImagen: MaestroVM[]
    },
    util: {
        existeCitaSeleccionada: boolean,
        opcionCitaSeleccionada: "PA" | "CO" | "RE" | "PE" | "RS" | "HC" | "MSP",
        subOpcionCitaSeleccionada:string,        
    }
}

export const initialState: CitaMedicaState = {
    citas: [],
    cita: {} as CitaMedicaVM,
    catalogos: {
        sexo: [],
        cie10: [],
        vademecum: [],
        examenesLaboratorio: [],
        examenesImagen: []
    },
    util: {
        existeCitaSeleccionada: false,
        opcionCitaSeleccionada: "PA",//Paciente,
        subOpcionCitaSeleccionada:'',        
    }
}


