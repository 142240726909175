import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'redux/state/nomina/mantenimiento/EmpleadoState'
import { EmpleadoVM } from 'core/domain/nomina/EmpleadoVM'
import { PersonaVM } from 'core/domain/comun/ExistePersonaVM'

export const empleadoSlice = createSlice({
    name: 'empleado',
    initialState,
    reducers: {
        setField: (state, action: PayloadAction<{ campo: string, valor:  any }>) => {
            return { ...state, [action.payload.campo]: action.payload.valor }
        },  
        setFieldEmpleado: (state, action: PayloadAction<{ campo: string, valor:  EmpleadoVM|EmpleadoVM[] }>) => {
            return { ...state, empleado:{...state.empleado, [action.payload.campo]: action.payload.valor} }
        },       
    },
})

export const { setField,setFieldEmpleado } = empleadoSlice.actions

export default empleadoSlice.reducer