import { Route, Routes } from "react-router-dom";
import { rutasPublicas } from '../../routes/rutasPublicas';
import * as page from '../../pages/index'

function PlantillaSinAutenticacion() {

    const routeComponents = rutasPublicas.map(({ path, component }, key) => <Route exact path={path} element={component} key={key} />);

    return (
        <Routes>
            <Route path="/" element={<page.Login/>}>
                {routeComponents}
                <Route path='*' element={<page.Login/>} />
            </Route>
        </Routes>
    )
}

export default PlantillaSinAutenticacion;