import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'redux/state/inventario/mantenimiento/ProductoState'
import { ProductoVM } from 'core/domain/inventario/ProductoVM'

export const productoSlice = createSlice({
    name: 'producto',
    initialState,
    reducers: {
        setField: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state, [action.payload.campo]: action.payload.valor }
        },
        setFieldProducto: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state, producto:{...state.producto,[action.payload.campo]: action.payload.valor} }
        }, 
        setFiltro: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state, filtros:{...state.filtros,[action.payload.campo]: action.payload.valor} }
        },                
        setListadoProducto: (state, action: PayloadAction<ProductoVM[]>) => {
            return { ...state, listadoProducto: action.payload }
        },
    },
})

export const {setField,setFieldProducto, setFiltro,setListadoProducto } = productoSlice.actions

export default productoSlice.reducer