import { lazy } from "react";

// Dashboard
export { default as Bienvenida } from './dashboard/Bienvenida'

// General
export const Sucursal = lazy(() => import("./general/mantenimiento/sucursal"))
export const FormularioSucursal = lazy(() => import('./general/mantenimiento/sucursal/FormularioSucursal'))

// Inventario
export const Categoria = lazy(() => import('./inventario/mantenimiento/categoria'))
export const Producto = lazy(() => import('./inventario/mantenimiento/producto'))
export const FormularioProducto = lazy(() => import('./inventario/mantenimiento/producto/FormularioProducto'))
export const PrecioVenta = lazy(() => import('./inventario/mantenimiento/precios-venta'))
export const FormularioPrecioVenta = lazy(() => import('./inventario/mantenimiento/precios-venta/FormularioPrecioVenta'))

// Medicina
export const Doctor = lazy(() => import('./medicina/mantenimiento/doctor'))
export const FormularioDoctor = lazy(() => import('./medicina/mantenimiento/doctor/FormularioDoctor'))
export const AgendarCita = lazy(() => import('./medicina/transaccion/agendarCita'))
export const GestionCitas = lazy(() => import('./medicina/transaccion/gestionCitas'))
export const Recepcion = lazy(() => import('./medicina/transaccion/recepcion'))
export const Enfermeria = lazy(() => import('./medicina/transaccion/enfermeria'))
export const Consulta = lazy(() => import('./medicina/transaccion/consulta'))
export const HistorialClinico = lazy(() => import('./medicina/consulta/historial-clinico'))

// Nómina
export const Cargo = lazy(() => import('./nomina/mantenimiento/cargo'))
export const FormularioCargo = lazy(() => import('./nomina/mantenimiento/cargo/FormularioCargo'))
export const Departamento = lazy(() => import('./nomina/mantenimiento/departamento'))
export const FormularioDepartamento = lazy(() => import('./nomina/mantenimiento/departamento/FormularioDepartamento'))
export const Empleado = lazy(() => import('./nomina/mantenimiento/empleado'))
export const FormularioEmpleado = lazy(() => import('./nomina/mantenimiento/empleado/FormularioEmpleado'))

// Seguridad
export const Login = lazy(() => import('./seguridad/transaccion/login'))
export const Pruebas = lazy(() => import('./seguridad/pruebas'))
export const NotFound = lazy(() => import('./seguridad/comun/NotFound'))
export const Rol = lazy(() => import('./seguridad/mantenimiento/rol'))
export const FormularioRol = lazy(() => import('./seguridad/mantenimiento/rol/FormularioRol'))
export const Usuario = lazy(() => import('./seguridad/mantenimiento/usuario'))
export const FormularioUsuario = lazy(() => import('./seguridad/mantenimiento/usuario/FormularioUsuario'))
export const Accesos = lazy(() => import('./seguridad/transaccion/accesos'))

// Venta
export const Empresa = lazy(() => import('./venta/mantenimiento/empresa'))
export const FormularioEmpresa = lazy(() => import('./venta/mantenimiento/empresa/FormularioEmpresa'))
export const Cliente = lazy(() => import('./venta/mantenimiento/cliente'))
export const FormularioCliente = lazy(() => import('./venta/mantenimiento/cliente/FormularioCliente'))
export const PreFacturas = lazy(() => import('./venta/transaccion/pre-facturas'))
export const FacturaVenta = lazy(() => import('./venta/transaccion/factura-venta'))
