import React from 'react'

const Bienvenida = () => {
    
    return (
        <div>
            Bienvenida            
        </div>
    )
}

export default Bienvenida
