import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { initialState } from 'redux/state/inventario/mantenimiento/CategoriaState'
import { CategoriaVM } from 'core/domain/inventario/CategoriaVM'

export const categoriaSlice = createSlice({
    name: 'categoria',
    initialState,
    reducers: {
        setField: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state, [action.payload.campo]: action.payload.valor }
        },
        setFieldCategoriaAGrabar: (state, action: PayloadAction<{ campo: string, valor: any }>) => {
            return { ...state, categoriaAGrabar: { ...state.categoriaAGrabar, [action.payload.campo]: action.payload.valor } }
        },  
        setListadoCategoria: (state, action: PayloadAction<CategoriaVM[]>) => {
            return { ...state, listadoCategoria: action.payload }
        },     
    },
})

export const { setField,setFieldCategoriaAGrabar,setListadoCategoria } = categoriaSlice.actions

export default categoriaSlice.reducer